body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  max-height: 600px;
  overflow: scroll;
  border: 1px solid #eee;
  background-color: #fefefe;
  border-radius: 6px;
  margin: 20px 0;
  display: block;
  padding: 20px;
  max-width: 40vw;
}

input {
  padding: 5px;
}
.files {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 100vw;
}
.file {
  margin: 0 20px;
}

.initFile {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
}

.download {
  float: right;
}

.loadData {
  display: block;
}

button {
  background-color: #008cba; /* Green */
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.download {
  background-color: green; /* Green */
}
